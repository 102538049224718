import request from './index'

export const influencerList = (data) => {
  return request({
    method: 'GET',
    url: '/influencer/list',
    params: data
  })
}

export const influencerDelete = (data) => {
  return request({
    method: 'PUT',
    url: '/influencer/status',
    data: data
  })
}

export const influencerAdd = (data) => {
  return request({
    method: 'POST',
    url: '/influencer/add',
    data: data
  })
}

export const influencerUpdate = (data) => {
  return request({
    method: 'PUT',
    url: '/influencer/update',
    data: data
  })
}

export const influencerDetail = (data) => {
  return request({
    method: 'GET',
    url: '/influencer/detail',
    params: data
  })
}

export const feedback = (data) => {
  return request({
    method: 'PUT',
    url: '/influencer/feedback',
    data: data
  })
}
export const addMedia = (data) => {
  return request({
    method: 'POST',
    url: '/influencer/addMedia',
    data: data
  })
}
