<template>
  <div>
    <b-card>
      <!-- Filter Form -->
      <div class="d-flex align-items-start filter-form">
        <div>
          <b-form
            class="mb-2"
            inline
          >

            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.searchType"
              :options="searchOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Search Type</b-form-select-option>
              </template>
            </b-form-select>

            <b-form-input
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.searchValue"
              placeholder="Search Input"
            ></b-form-input>
            <b-form-input
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.name"
              placeholder="Agency Name"
            ></b-form-input>
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.by_logged"
              :options="agencyOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Select Agency</b-form-select-option>
              </template>
            </b-form-select>
            <b-button
              pill
              class="mt-lg-0 mt-md-2 mr-sm-2"
              variant="primary"
              @click="search()"
            >Search</b-button>
            <b-button
              pill
              class="mt-lg-0 mt-md-2 mr-sm-2"
              variant="primary"
              @click="resetFilter()"
            >Reset</b-button>
          </b-form>
        </div>
        <b-overlay
          :show="exportLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          @hidden="!exportLoading"
        >
          <b-dropdown
            class="ml-auto"
            variant="outline-white"
            right
            no-caret
          >
            <template #button-content>
              <b-icon icon="list"></b-icon>
            </template>
            <b-dropdown-item @click="exportdata()">
              <b-icon icon="download"></b-icon>
              Export
            </b-dropdown-item>
            <b-dropdown-item @click="addmodal">
              <b-icon icon="person-plus"></b-icon>
              Add Agency
            </b-dropdown-item>
          </b-dropdown>
        </b-overlay>
      </div>

      <hr />

      <!-- Data Table -->
      <div
        class="d-block text-center"
        v-if="!tabledata.length && !loading"
      >
        <img
          src="@/assets/images/no-data.png"
          width="150"
        />
        <h5 class="mt-2">No Record Found</h5>
      </div>
      <b-table
        :items="tabledata"
        :fields="fields"
        id="tabledata"
        :busy="loading"
        head-variant="light"
        responsive
        hover
        v-else
      >
        <template #table-busy>
          <div class="text-center text-primary mt-5 mb-1">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(name)="data">
          {{ data.value || 'NA' }}
        </template>
        <template #cell(mobile)="data">
          {{ data.value || 'NA' }}
        </template>
        <template #cell(email)="data">
          {{ data.value || 'NA'}}
        </template>
        <template #cell(add_time)="data">
          {{ data.value | dateFormat}}
        </template>

        <template #cell(action)="data">
          <b-dropdown
            variant="outline-white"
            no-caret
          >
            <template #button-content>
              <b-icon icon="three-dots-vertical"></b-icon>
            </template>
            <b-dropdown-item @click="editmodal(data.item)">
              <b-icon icon="pencil-square"></b-icon>
              Edit
            </b-dropdown-item>
            <b-dropdown-item @click="deleteAgency(data.item.uuid)">
              <b-icon icon="trash"></b-icon>
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-if="total > 20 && !loading"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        align="center"
        pills
        @input="handlePageClick"
      ></b-pagination>
      <!-- AddAgency Modal  -->
      <b-modal
        title="Add Agency"
        ref="addModal"
        hide-footer
        centered
      >
        <validation-observer ref="addchannelrules">
          <b-form>

            <b-form-group label="Agency Name">
              <validation-provider
                #default="{ errors }"
                name="Agency Name"
                rules="required"
              >
                <b-form-input
                  v-model="addAgency.name"
                  placeholder="Agency Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Mobile No">
              <validation-provider
                #default="{ errors }"
                name="Mobile No"
                :rules="{ required: true, regex: /^[0-9]{10}$/ }"
              >
                <b-form-input
                  v-model="addAgency.mobile"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Mobile Number"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="email"
              >
                <b-form-input
                  v-model="addAgency.email"
                  placeholder="Email Address"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="d-flex justify-content-end mt-4">
              <b-button
                pill
                class="mr-2"
                variant="secondary"
                @click="cancelAdd"
              >Cancel</b-button>
              <b-button
                pill
                class="mr-2"
                variant="primary"
                @click="resetAgency"
              >Reset</b-button>
              <b-button
                pill
                type="submit"
                variant="primary"
                @click.prevent="addagency"
              >Add</b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- EditAgency Modal  -->
      <b-modal
        title="Edit Agency"
        ref="editModal"
        hide-footer
        centered
      >
        <validation-observer ref="simpleRules">
          <b-form>

            <b-form-group label="Agency Name">
              <validation-provider
                #default="{ errors }"
                name="Agency Name"
                rules="required"
              >
                <b-form-input
                  v-model="editAgency.name"
                  placeholder="Agency Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Mobile No">
              <validation-provider
                #default="{ errors }"
                name="Mobile No"
                :rules="{ required: true, regex: /^[0-9]{10}$/ }"
              >
                <b-form-input
                  :disabled="editAgency.by_logged ? true : false"
                  v-model="editAgency.mobile"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Mobile Number"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="email"
              >
                <b-form-input
                  v-model="editAgency.email"
                  placeholder="Email Address"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="d-flex justify-content-end mt-4">
              <b-button
                pill
                class="mr-2"
                variant="secondary"
                @click="cancelEdit"
              >Cancel</b-button>
              <b-button
                pill
                type="submit"
                variant="primary"
                @click.prevent="updateAgency"
              >Update</b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { influencerList, influencerDelete, influencerAdd, influencerUpdate } from '@/api/influencer'
export default {
  data () {
    return {
      perPage: 20,
      currentPage: 1,
      editModal: false,
      addModal: false,
      tabledata: [],
      loading: true,
      exportLoading: false,
      total: 0,
      filter: {
        searchType: null,
        searchValue: null,
        inf_type: 2,
        name: null,
        by_logged: null,
        p: 1,
        size: 20
      },
      agencyOptions: [
        { value: 0, text: 'Admin Agency' },
        { value: 1, text: 'Signup Agency' }
      ],
      fields: [
        { key: 'index', label: 'Sr.' },
        { key: 'name', label: 'Agency Name' },
        { key: 'mobile', label: 'Mobile' },
        { key: 'email', label: 'Email' },
        { key: 'add_time', label: 'Add Time' },
        'action'
      ],
      editAgency: {
      },
      addAgency: {
        mobile: null,
        name: null,
        email: null
      },
      searchOptions: [
        { value: 'contact', text: 'Contact' },
        { value: 'email', text: 'Email' }
      ]
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    exportdata () {
      this.exportLoading = true
      var influencerWS = XLSX.utils.json_to_sheet(this.tabledata)
      var wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, influencerWS, 'AgencyData')
      setTimeout(() => {
        XLSX.writeFile(wb, 'Agency.xlsx')
        this.exportLoading = false
      }, 2000)
    },
    resetFilter () {
      this.filter = {
        searchType: null,
        name: null,
        searchValue: null,
        by_logged: null,
        p: 1,
        size: 20,
        inf_type: 2
      }
      this.currentPage = 1
      this.getData()
    },
    addmodal () {
      this.$refs.addModal.show()
    },
    editmodal (item) {
      this.editAgency = {
        mobile: item.mobile,
        uuid: item.uuid,
        name: item.name,
        email: item.email,
        by_logged: item.by_logged
      }
      this.$refs.editModal.show()
    },
    cancelAdd () {
      this.resetAgency()
      this.$refs.addModal.hide()
    },
    cancelEdit () {
      this.$refs.editModal.hide()
    },
    resetAgency () {
      this.addAgency = {
        mobile: null,
        name: null,
        email: null
      }
    },
    handlePageClick (pageNumber) {
      this.filter.p = pageNumber
      this.getData()
    },
    search () {
      if (this.filter.searchValue && !this.filter.searchType) {
        return this.$bvToast.toast('Please select search type', {
          title: 'Search error!',
          variant: 'danger',
          solid: true
        })
      }
      this.currentPage = 1
      this.filter.p = 1
      this.getData()
    },
    async getData () {
      this.loading = true
      const response = await influencerList(this.filter)
      if (response.succ) {
        this.tabledata = response.data
        this.total = response.totaluser
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loading = false
    },
    async deleteAgency (uuid) {
      try {
        this.$bvModal.msgBoxConfirm('Are are you sure want to delete this agency?', {
          title: 'Please Confirm',
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'sm',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          const data = {
            actionUser: window.localStorage.getItem('user-name'),
            uuid: uuid,
            is_active: 2
          }
          if (value) {
            const response = await influencerDelete(data)
            if (response.succ) {
              this.$bvToast.toast('Agency deleted successfully', {
                title: 'Deleted',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
              this.tabledata.splice(this.tabledata.findIndex(a => a.uuid === uuid), 1)
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Delete error!',
                variant: 'danger',
                solid: true
              })
            }
          }
        }).catch((error) => {
          console.log('cancel delete. error', error)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async updateAgency () {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to update this agency?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const data = {
                actionUser: window.localStorage.getItem('user-name'),
                uuid: this.editAgency.uuid,
                email: this.editAgency.email,
                mobile: this.editAgency.mobile,
                name: this.editAgency.name
              }
              if (value) {
                const response = await influencerUpdate(data)
                if (response.succ) {
                  this.$bvToast.toast('Agency updated successfully', {
                    title: 'Updated',
                    variant: 'success',
                    autoHideDelay: 2000,
                    solid: true
                  })
                  this.getData()
                  this.$refs.editModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Update error!',
                    variant: 'danger',
                    solid: true
                  })
                }
              }
            }).catch(() => {
              console.log('cancel update.')
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    async addagency () {
      this.$refs.addchannelrules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to add this agency?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const data = {
                actionUser: window.localStorage.getItem('user-name'),
                email: this.addAgency.email,
                mobile: this.addAgency.mobile,
                name: this.addAgency.name,
                inf_type: 2
              }
              if (value) {
                const response = await influencerAdd(data)
                if (response.succ) {
                  this.$bvToast.toast('Agency added successfully', {
                    title: 'Added Agency',
                    variant: 'success',
                    autoHideDelay: 2000,
                    solid: true
                  })
                  this.getData()
                  this.resetAgency()
                  this.$refs.addModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Add agency error!',
                    variant: 'danger',
                    solid: true
                  })
                }
              }
            }).catch(() => {
              console.log('cancel add.')
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    }
  }
}
</script>

<style datad>
  .card-body >>> .table {
    font-size: 14px;
  }
  .table td p {
    margin: 0 0 0 10px;
    line-height: 18px;
  }
  .table td a.meta {
    text-decoration: none;
    color: #4c62e3;
    text-transform: uppercase;
  }
  .table td img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .table td p small {
    color: #f54153;
    font-size: 13px;
    text-transform: capitalize;
  }
</style>
